<template>
  <div>
    <CRow class="d-flex justify-content-between p-2">
      <CCol col="12" style="text-align: center">
        <div class="avatar-upload" col="6">
          <img
            :src="generateFullUrlWithToken(item.profile_picture)"
            class="rounded-circle mb-3"
            style="width: 50%"
            onerror="this.onerror=null;this.onload=null;this.src='/img/user_default_pic.png';"
            alt
          />
          <div
            class="avatar-edit"
            v-if="item.profile_picture === '/img/user_default_pic.png'"
          >
            <input
              id="imageUpload"
              type="file"
              ref="profilefileref"
              name="image"
              accept="image/*"
              @change="selectedFile"
            />
            <label for="imageUpload" class="p-2 text-muted font-weight-bold"
              >Click here to upload photo</label
            >
            <div v-if="isImageSelected">
              <UploadDocument
                class="mt-4"
                :fileData="fileData"
                @closeModal="closeModal"
                :isProfilePic="true"
                v-on="$listeners"
                @upload="photoUpload"
              />
            </div>
          </div>
        </div>
        <center>
          <h4 class="text-primary" v-if="!isEducationalType">
            {{ candidateType }} 
            <span v-if="item.main_qualification"
              >, {{ item.qualification }}({{
                item.main_qualification_year
              }})</span
            >
          </h4>
          <h4 v-else>
            Student
          </h4>
          <span class="mx-1" v-if="item.gender"
            ><b>{{ item.gender }}</b></span
          >
          <span class="mx-1" v-if="item.age">{{ item.age }} Years</span>
          <div v-if="item.nationality.length">
            <span>{{ item.nationality.join(", ") || "--" }}</span>
          </div>
          <div class="pb-2">
            <!-- <span>Candidate ID: </span><b>{{ item.candidate_uid }}</b> -->
            <span v-if="!isEducationalType">Candidate ID: <b>{{ item.display_uid }}</b></span>
            <span v-if="isEducationalType">Student ID: <b>{{ item.display_uid }}</b></span>
          </div>
          <div class="text-primary" style="font-size: 12px">
            <span>Registered: </span>{{ createdOnDate }}
          </div>
          <div class="text-primary" style="font-size: 12px">
            <span>Last Login: </span>{{ lastLogin }}
          </div>
        </center>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <div class="item-info small-text center">
          <i class="far fa-envelope"></i>
          <span class="info center">{{ item.email }}</span>
        </div>
        <div class="item-info small-text center">
          <i class="fa fa-phone-alt"></i>
          <span class="info">{{ item.phone.replace("^", "-") }}</span>
        </div>
        <div class="item-info small-text center">
          <i class="fab fa-whatsapp"></i>
          <span class="info">{{
            item.whatsapp.replace("^", "-") || "--"
          }}</span>
        </div>
      </CCol>
    </CRow>

    <CRow class="pb-2">
      <CCol>
        <h5><span class="item-info" item.location></span></h5>
        <span
          class="step bg-secondary"
          v-if="item.retired_clinician === 'Yes'"
          v-c-tooltip="'Retired'"
          >R</span
        >
        <span
          class="step bg-secondary"
          v-if="item.volunteer === 'Yes'"
          v-c-tooltip="'Volunteer'"
          >V</span
        >
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { Role, getScope,  CONSTANT as CONST, generateFullUrlWithToken  } from "@/helpers/helper";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
export default {
  name: "CandidateInfoUserView",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  components: {
    UploadDocument,
  },
  data() {
    return {
      isImageSelected: false,
      fileData: {},
      isFileError: false,
      currentUserRole: getScope(),
      Role,
      generateFullUrlWithToken,
    };
  },
  computed: {
    ...mapGetters([
      "getStatusIcon",
      "getStatusColor",
      "getGenderColor",
      "getGenderImage",
      "candidateProfile",
      "uploaded",
    ]),
    lastLogin() {
      return this.item.last_date_of_login
        ? moment(this.item.last_date_of_login).format("DD-MMM-YYYY")
        : "--";
    },
    createdOnDate() {
      return this.item.created_on
        ? moment(this.item.created_on).format("DD-MMM-YYYY")
        : "--";
    },
    candidateType() {
      return this.candidateProfile?.candidate_type_id === 3
        ? this.candidateProfile?.candidate_type?.comments
        : this.candidateProfile?.candidate_type?.candidate_type;
    },
    isEducationalType() {
      return this.candidateProfile?.customer_type_id ===  CONST.CUSTOMER_TYPES.educational_institute || false
    }
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        const { customer_uid: customer } = this.candidateProfile;
        this.getUserMe({ customer });
        this.$refs.profilefileref = "";
        this.isImageSelected = false;
        this.fileData = {};
      }
    },
  },
  methods: {
    ...mapActions(["uploadPhoto", "getUserMe"]),
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        this.isFileError = false;
        const file = event.target.files[0];
        this.isImageSelected = true;
        this.fileData = file;
      } else {
        this.$refs.profilefileref.value = "";
        this.isFileError = true;
      }
    },
    closeModal() {
      this.$refs.profilefileref.value = "";
      this.fileData = {};
      this.isImageSelected = false;
    },
    photoUpload(payload) {
      this.$store.commit("SET_UPLOADED");
      const { customer_uid, candidate_uid, user_id } = this.candidateProfile;
      const document_type = payload.document_type || 1;
      this.uploadPhoto({
        customer_uid,
        candidate_uid,
        user_id,
        [payload.name]: payload.value,
        document_type,
        ["fileName"]: payload.document_name,
      });
    },
  },
  filters: {
    foramtDate(date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.rounded-circle {
  border: 1px solid #dad6d6;
  box-shadow: -1px -1px 8px 0px #b9b9b9;
}
i {
  min-width: 20px;
}
.cust-progress {
  height: 8px !important;
}
.item-info {
  .info-small-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    .info {
      margin-left: 5px;
    }
  }
}
.circle-border {
  border-radius: 50%;
  padding: 2px;
  margin: 2px;
}
span.step {
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: $white;
  display: inline-block;
  font-family: Poppins, sans-sarif !important;
  font-weight: 300;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
  opacity: 0.9;
}
.center {
  text-align: center;
  justify-content: center;
}
.align-center {
  align-items: center;
  justify-content: center;
}
</style>
